:root {
  --soft-blue-color: #6b7094;
  --dark-text-color: #334451;
  --cream: #fff4e4;
  --medium-dark-text: #4d6577;
  --white: white;
  --turqoise: #d5f5f3;
  --light-red: #ffeae4;
  --light-blue: #e0f2ff;
  --light-cream: #f0efe3;
  --soft-gray: var(--white);
  --black: black;
  --heading-1: Inter, sans-serif;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-pagination-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.w-pagination-previous {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.w-pagination-previous-icon {
  margin-right: 4px;
}

.w-pagination-next {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.w-pagination-next-icon {
  margin-left: 4px;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

.heading-set {
  z-index: 3;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: relative;
}

.heading-set-2 {
  width: 100%;
  max-width: 648px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.tagline-heading {
  color: var(--soft-blue-color);
  letter-spacing: .08em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.heading-set-3 {
  width: 100%;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.heading-1 {
  color: var(--dark-text-color);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  text-decoration: none;
}

.description {
  color: var(--soft-blue-color);
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
}

.container {
  max-width: 1200px;
  flex: 1;
  align-items: center;
  display: block;
}

.container.mid-aligned {
  justify-content: center;
  display: flex;
}

.container.flex {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.body {
  font-family: Inter, sans-serif;
}

.hero-section {
  height: 650px;
  background-color: var(--cream);
  background-image: url('/images/Design-Element.svg'), linear-gradient(to bottom, rgba(255, 244, 228, .5), var(--cream)), url('/images/FDI.png');
  background-position: 100% 100%, 0 0, 50% -250px;
  background-repeat: no-repeat, repeat, no-repeat;
  background-size: auto, auto, cover;
  background-attachment: scroll, scroll, scroll;
  padding-top: 200px;
}

.hero-section.h-550 {
  height: 550px;
}

.navbar {
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(221, 221, 221, 0.1);
  border-bottom: 1px solid #fff;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  display: flex;
  position: fixed;
}

.vectors-wrapper {
  width: 120px;
  height: auto;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper.investment-office {
  width: 200px;
  height: 40px;
}

.nav-button {
  color: #334451;
  cursor: pointer;
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
}

.nav-button.fdi-term-list {
  background-color: #F1EFE4;
  border-radius: 10px;
  padding: 15px 15px;
  font-weight: 700;
  font-size: 20px;
  height: 58px;
  vertical-align: middle;
  color: #2f414f;
}

.nav-button:hover {
  /* color: var(--medium-dark-text); */
  transform: scale(1.05);
}

.nav-button.w--current {
  color: var(--medium-dark-text);
}

.nav-button.light {
  color: var(--white);
  text-transform: uppercase;
}

.navlinks .search {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 15px;
  background: #F1EFE4 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  width: 58px;
  height: 58px;
  cursor: pointer;
}

.navlinks .search .icons-search {
  background-color: transparent;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.3);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: auto;
}

.navlinks .search .search-input {
  display: none;
  height: 43px;
  line-height: 43px;
  background: none;
  border: none;
  outline: none;
  color: #ffffff;
  width: 100%;
  width: calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
}

.hero-content-div {
  align-items: stretch;
  display: flex;
}

.navbar-container {
  max-width: 1200px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.nav {
  width: 100%;
  background-color: #2b2b2b;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 87.25px 120px 22px;
  text-decoration: none;
  display: flex;
}

.div-container {
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.div-row {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-right: .05px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2 {
  width: 171.422px;
  height: 720.25px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 335px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.p-title {
  grid-row-gap: 5.75px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 20px;
  text-decoration: none;
  display: flex;
}

.text-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 25.5px;
  text-decoration: none;
}

.vectors-wrapper-2 {
  width: 82.06px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.list {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.item {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 56.26px;
  text-decoration: none;
  display: flex;
}

.link-who-we-are {
  color: #d6d6d6;
  letter-spacing: .01px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-decoration: none;
}

.item-2 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 47.67px;
  text-decoration: none;
  display: flex;
}

.link {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 6.75px;
  text-decoration: none;
  display: flex;
}

.message-from-the-president {
  color: #d6d6d6;
  letter-spacing: .01px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-decoration: none;
}

.item-3 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 58.08px;
  text-decoration: none;
  display: flex;
}

.link-2 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 58.89px;
  text-decoration: none;
  display: flex;
}

.item-4 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 61.5px;
  text-decoration: none;
  display: flex;
}

.link-3 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 31.58px;
  text-decoration: none;
  display: flex;
}

.item-5 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 57.87px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2-2 {
  width: 171.422px;
  height: 720.25px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 387px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-3 {
  width: 110.31px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.link-4 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 18.75px;
  text-decoration: none;
  display: flex;
}

.item-6 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 45.09px;
  text-decoration: none;
  display: flex;
}

.item-7 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 22.84px;
  text-decoration: none;
  display: flex;
}

.item-8 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 43.78px;
  text-decoration: none;
  display: flex;
}

.link-5 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 56.7px;
  text-decoration: none;
  display: flex;
}

.item-9 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 61.61px;
  text-decoration: none;
  display: flex;
}

.item-10 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 22.22px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2-3 {
  width: 171.422px;
  height: 745.25px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 66px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-4 {
  width: 75.09px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.item-11 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 54.75px;
  text-decoration: none;
  display: flex;
}

.item-12 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 64.15px;
  text-decoration: none;
  display: flex;
}

.link-6 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 63.67px;
  text-decoration: none;
  display: flex;
}

.item-13 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 88.89px;
  text-decoration: none;
  display: flex;
}

.link-7 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 35.29px;
  text-decoration: none;
  display: flex;
}

.item-14 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 120.31px;
  text-decoration: none;
  display: flex;
}

.item-15 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 24.72px;
  text-decoration: none;
  display: flex;
}

.item-16 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 46.25px;
  text-decoration: none;
  display: flex;
}

.item-17 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 62.37px;
  text-decoration: none;
  display: flex;
}

.item-18 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 68.33px;
  text-decoration: none;
  display: flex;
}

.link-8 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 22.51px;
  text-decoration: none;
  display: flex;
}

.item-19 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 10.48px;
  text-decoration: none;
  display: flex;
}

.item-20 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 4.45px;
  text-decoration: none;
  display: flex;
}

.item-21 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 23.19px;
  text-decoration: none;
  display: flex;
}

.item-22 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 55.95px;
  text-decoration: none;
  display: flex;
}

.item-23 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 81.51px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2-4 {
  width: 171.421px;
  height: 719.25px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 215.5px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-5 {
  width: 141.42px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.link-9 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 33.8px;
  text-decoration: none;
  display: flex;
}

.link-10 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 13.53px;
  text-decoration: none;
  display: flex;
}

.link-11 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 15.31px;
  text-decoration: none;
  display: flex;
}

.link-12 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 40.72px;
  text-decoration: none;
  display: flex;
}

.link-13 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 39.74px;
  text-decoration: none;
  display: flex;
}

.item-24 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 8.36px;
  text-decoration: none;
  display: flex;
}

.item-25 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 20.26px;
  text-decoration: none;
  display: flex;
}

.item-26 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 72.58px;
  text-decoration: none;
  display: flex;
}

.item-27 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 56.59px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2-5 {
  width: 171.422px;
  height: 718.75px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 584px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-6 {
  width: 69.42px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.item-28 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 45.89px;
  text-decoration: none;
  display: flex;
}

.item-29 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 59.78px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2-6 {
  width: 171.422px;
  height: 718.25px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 615px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-7 {
  width: 63.25px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.list-item {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 62.5px;
  text-decoration: none;
  display: flex;
}

.div-col-md-2-7 {
  width: 171.421px;
  height: 740.25px;
  max-width: 171.428px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 501px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-8 {
  width: 46.91px;
  height: 4px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.item-30 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 2.72px;
  text-decoration: none;
  display: flex;
}

.item-31 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 45.31px;
  text-decoration: none;
  display: flex;
}

.item-32 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10.5px;
  padding-right: 30.01px;
  text-decoration: none;
  display: flex;
}

.link-14 {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-right: 19.92px;
  text-decoration: none;
  display: flex;
}

.section {
  width: 100%;
  background-color: #2b2b2b;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 45px 120px 35px;
  text-decoration: none;
  display: flex;
}

.div-row-2 {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.div-col-md-4 {
  width: 100%;
  height: 77px;
  max-width: 400px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.link-15 {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 8px;
  text-decoration: none;
  display: flex;
}

.cbyo-logo-png {
  object-fit: cover;
}

.div-col-md-4-2 {
  width: 100%;
  height: 133px;
  max-width: 400px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 21px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.p-copyright {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 98.25px;
  padding-right: 98.25px;
  text-decoration: none;
  display: flex;
}

.text-3 {
  color: #929292;
  text-align: center;
  letter-spacing: .01px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 46px;
  text-decoration: none;
}

.div-socials {
  width: 100%;
  grid-column-gap: 19.2px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 29.06px;
  padding-right: 14.06px;
  text-decoration: none;
  display: flex;
}

.link-16 {
  border: 2px solid #4d4d4d;
  border-radius: 100px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 18.02px 16.49px 14.98px;
  text-decoration: none;
  display: flex;
}

.icon-icon-linkedin {
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.before {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-9 {
  width: 17.02px;
  height: 17px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.text-4 {
  color: #fff;
  text-align: center;
  letter-spacing: .01px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 17px;
  text-decoration: none;
}

.link-17 {
  border: 2px solid #4d4d4d;
  border-radius: 100px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 18.02px 16.5px 14.98px 16.48px;
  text-decoration: none;
  display: flex;
}

.div-col-md-4-3 {
  width: 100%;
  height: 133px;
  max-width: 400px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 56.64px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: flex;
}

.div-ft-right-logo-content {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 48.11px;
  text-decoration: none;
  display: flex;
}

.link-18 {
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.overlapping-div {
  max-width: 1200px;
  min-height: 600px;
  background-color: #fff;
  border-radius: 40px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 40px;
  display: flex;
  position: relative;
  transform: translate(0, -160px);
  box-shadow: 0 0 100px rgba(0, 0, 0, .25);
}

.base-cards-section {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.glossary-card {
  width: 240px;
  height: 360px;
  white-space: normal;
  cursor: pointer;
  background-color: #e6e6e6;
  background-image: url('/images/Design-Element.svg');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 120%;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px 12px 32px;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.glossary-card:hover {
  background-size: 100%;
  padding-bottom: 40px;
  transform: scale(1.02);
  box-shadow: 2px 2px 50px rgba(0, 0, 0, .2);
}

.glossary-card.turqoise {
  background-color: var(--turqoise);
}

.glossary-card.red {
  background-color: var(--light-red);
}

.glossary-card.blue {
  background-color: var(--light-blue);
}

.glossary-card.cream {
  background-color: var(--light-cream);
}

.glossary-card-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 240px 240px 240px 240px;
  grid-auto-columns: 1fr;
  display: grid;
}

.card-heading {
  z-index: 2;
  color: #334451;
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  text-decoration: none;
  position: relative;
}

.card-overlay {
  width: 240px;
  height: 360px;
  background-image: linear-gradient(to bottom, rgba(230, 230, 230, 0) 30%, var(--soft-gray) 65%);
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.card-overlay.turqoise {
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(213, 245, 243, 0) 50%, var(--turqoise));
}

.card-overlay.red {
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 234, 228, 0) 30%, var(--light-red) 65%);
}

.card-overlay.blue {
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(224, 242, 255, 0) 30%, var(--light-blue) 65%);
}

.card-overlay.image {
  background-image: url('/images/Design-Element.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 120%;
}

.card-overlay.image._2 {
  background-image: url('/images/turqoise-sample.png');
}

.card-overlay.image._3 {
  background-image: url('/images/light-red-sample.png');
}

.card-overlay.image._4 {
  background-image: url('/images/blue-sample.png');
}

.card-overlay.cream {
  background-image: linear-gradient(to bottom, rgba(240, 239, 227, 0) 30%, var(--light-cream) 65%);
}

.card-overlay.trqoise {
  background-image: linear-gradient(to bottom, rgba(213, 245, 243, 0) 30%, var(--turqoise) 65%);
}

.card-overlay.base.hidden {
  display: none;
}

.cards-display {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  overflow: visible;
}

.link-block {
  cursor: pointer;
  text-decoration: none;
}

.link-block.full-width {
  display: block;
}

.typography-section,
.dynamic-cards-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.dynamic-cards-section.p0-100 {
  padding-top: 0;
}

.glossary-top-row {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 48px;
  display: flex;
}

.glossary-letter-group {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.newsletter-block {
  width: 100%;
  grid-row-gap: 24px;
  background-color: var(--soft-gray);
  text-align: center;
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  padding: 32px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 300px;
}

.field-icon-b {
  width: 32px;
  height: 32px;
  background-color: var(--light-cream);
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: auto;
}

.search-icon {
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 99px;
  padding: 8px;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: auto;
}

.icon-small {
  width: 16px;
  height: 16px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.glossary-content-card {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.glossary-form {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.glossary-search {
  width: 100%;
  max-width: 486px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
  display: flex;
}

.span-primary {
  color: var(--soft-blue-color);
}

.search-input {
  min-height: 48px;
  background-color: var(--white);
  letter-spacing: -.02em;
  cursor: text;
  border: 1px solid #eaedf0;
  border-radius: 99px;
  margin-bottom: 0;
  padding: 8px 8px 8px 48px;
  font-size: 18px;
  line-height: 1.4;
}

.search-input::-ms-input-placeholder {
  color: #adb5bd;
}

.search-input::placeholder {
  color: #adb5bd;
}

.paragraph-large {
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1.8;
}

.paragraph-large.text-weight-bold {
  color: var(--dark-text-color);
  white-space: normal;
  font-weight: 700;
}

.paragraph-large.limited {
  max-width: 900px;
}

.glossary-dropdown {
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.accordian-letter {
  width: 32px;
  height: 32px;
  background-color: var(--light-cream);
  color: var(--dark-text-color);
  letter-spacing: -.02em;
  text-transform: uppercase;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  transition: color .3s, border-color .3s;
  display: flex;
}

.accordian-letter:hover {
  color: #6173e5;
  border-color: #6173e5;
}

.wrap-v-small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.glossary-word-wrap {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}

.glossary-toggle {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
}

.h6-heading {
  color: var(--dark-text-color);
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 1.4;
}

.glossary-card-2 {
  width: 100%;
  max-width: 486px;
  grid-row-gap: 16px;
  border: 1px solid #eaedf0;
  border-radius: 8px;
  flex-direction: column;
  padding: 24px;
  display: flex;
}

.section-regular {
  padding: 80px 5%;
  position: relative;
}

.glossary-input {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.caption-bold {
  color: #353b41;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
}

.glossary-letter-link {
  width: 32px;
  height: 32px;
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: #fff;
  border: .5px solid #eaedf0;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  transition: color .3s, border-color .3s;
  display: flex;
}

.glossary-letter-link:hover {
  color: #6173e5;
  border-color: #6173e5;
}

.glossary-letter-link.jetboost-filter-active {
  background-color: var(--dark-text-color);
  color: var(--white);
}

.container-regular {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.newsletter-icon {
  width: 64px;
  height: 64px;
  color: #6173e5;
  background-color: #fff;
  border-radius: 99px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.icon-regular {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.field-icon {
  width: 16px;
  height: 16px;
  color: var(--dark-text-color);
  justify-content: center;
  align-items: center;
  display: flex;
}

.glossary-content {
  width: 100%;
  margin-top: -6px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.glossary-word-line {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  white-space: normal;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.icon-large {
  width: 32px;
  height: 32px;
  color: var(--dark-text-color);
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-primary {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--dark-text-color);
  color: #fff;
  text-align: center;
  letter-spacing: -.02em;
  cursor: pointer;
  border-radius: 99px;
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition: all .2s;
  display: flex;
}

.button-primary:hover {
  background-color: var(--medium-dark-text);
}

.form-block {
  margin-bottom: 0;
}

.wrap-h-small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  display: flex;
}

.glossary-refresh {
  width: 32px;
  height: 32px;
  color: #e56161;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: #fff;
  border: .5px solid #eaedf0;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  transition: color .3s, border-color .3s;
  display: flex;
}

.glossary-refresh:hover {
  border-color: #e56161;
}

.wrap-v-regular {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}

.glossary-content-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
  position: relative;
}

.paragraph-regular {
  color: var(--soft-blue-color);
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.8;
}

.search-input-wrapper {
  width: 100%;
  position: relative;
}

.glossary-list {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: block;
  position: relative;
  overflow: hidden;
}

.h2-heading {
  color: var(--dark-text-color);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 56px;
  line-height: 1.2;
}

.glossary-icon-wrapper {
  width: 32px;
  height: 32px;
  color: #000;
  background-color: #f8f9fa;
  border: 1px solid #eaedf0;
  border-radius: 99px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.field-input-b {
  width: 100%;
  min-height: 48px;
  letter-spacing: -.02em;
  background-color: #fff;
  border: 1px solid #eaedf0;
  border-radius: 99px;
  margin-bottom: 0;
  padding: 12px 12px 12px 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .25s;
}

.field-input-b::-ms-input-placeholder {
  color: #adb5bd;
}

.field-input-b::placeholder {
  color: #adb5bd;
}

.glossary-letter-wrap {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 8px;
  display: flex;
  position: relative;
}

.glossary-letter-wrap.hide {
  display: none;
}

.sliding-cards-section {
  height: 400px;
  overflow: visible;
}

.section-2.is--hero {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.container-2 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 5em 2.5em;
}

.marquee-wrapper {
  width: 100%;
  height: 520px;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  overflow: auto;
  transform: translate(0, -160px);
}

.marquee-track {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  transform: translate(0);
}

.marquee-list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.marquee-item {
  cursor: pointer;
  border-left: 1px solid rgba(223, 223, 223, .23);
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.collection-list-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  display: flex;
}

.dynamic-hero-section {
  height: 650px;
  background-color: var(--cream);
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg'), url('/images/Design-Element.svg'), linear-gradient(to bottom, rgba(255, 244, 228, 0), var(--cream));
  background-position: 100%, 0%, 0 0;
  background-repeat: no-repeat, no-repeat, repeat;
  background-size: contain, auto, auto;
  background-attachment: scroll, scroll, scroll;
  padding-top: 180px;
  position: relative;
}

.hero-overlay {
  width: 650px;
  height: 650px;
  background-image: linear-gradient(to right, var(--soft-gray), rgba(230, 230, 230, 0));
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.hero-overlay.red {
  background-image: linear-gradient(to right, var(--light-red), rgba(255, 234, 228, 0) 50%);
}

.hero-overlay.blue {
  background-image: linear-gradient(to right, var(--light-blue), rgba(224, 242, 255, 0) 50%);
}

.hero-overlay.turqoise {
  background-image: linear-gradient(to right, var(--turqoise), rgba(213, 245, 243, 0) 50%);
}

.hero-overlay.cream {
  background-image: linear-gradient(to right, var(--light-cream), rgba(240, 239, 227, 0) 50%, rgba(240, 239, 227, 0));
}

.hero-overlay.hide {
  display: none;
}

.paragraph-regular-copy {
  color: var(--soft-blue-color);
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.8;
}

.transparent-image {
  z-index: 3;
  width: 650px;
  height: 650px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.transparent-image.hide {
  display: none;
}

.term-detail-overlap {
  max-width: 1200px;
  border-top: 1px solid var(--white);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, .2);
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 40px;
  position: relative;
  transform: translate(0, -150px);
  box-shadow: 0 0 100px rgba(0, 0, 0, .25);
}

.content-card {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--soft-gray);
  cursor: pointer;
  border-radius: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 40px;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
  display: grid;
  position: relative;
  overflow: visible;
}

.h3-heading {
  color: var(--dark-text-color);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 40px;
  line-height: 1.2;
}

.card-image {
  z-index: 2;
  width: 500px;
  height: 500px;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.card-image.transparent {
  z-index: 2;
}

.card-image.overflow {
  height: 400px;
  overflow: hidden;
}

.card-image-overlay {
  width: 500px;
  height: 100%;
  background-image: linear-gradient(to right, var(--light-red), rgba(255, 234, 228, 0));
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.card-image-overlay.turqoise {
  z-index: 1;
  width: 250px;
  background-image: linear-gradient(to right, var(--turqoise), rgba(213, 245, 243, 0));
}

.card-image-overlay.cream {
  z-index: 1;
  background-image: linear-gradient(to right, var(--light-cream), rgba(240, 239, 227, 0));
}

.card-image-overlay.blue {
  z-index: 1;
  background-image: linear-gradient(to right, var(--light-blue), rgba(224, 242, 255, 0));
}

.card-image-overlay.red {
  z-index: 1;
}

.card-image-overlay.soft-gray {
  background-image: linear-gradient(to right, var(--soft-gray), rgba(248, 249, 250, 0));
}

.card-image-copy {
  width: 500px;
  height: 500px;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.collection-list {
  grid-column-gap: 150px;
  grid-row-gap: 150px;
  flex-direction: column;
  display: flex;
}

.vertical-flex {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  flex-direction: column;
  display: flex;
}

.section-3 {
  height: auto;
}

.content-listing-card {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--soft-gray);
  border-radius: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: end;
  padding: 40px;
  display: grid;
  position: relative;
  overflow: visible;
}

.listing-card-heading {
  color: var(--dark-text-color);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 1.2;
}

.listing-card-image {
  z-index: 2;
  width: 300px;
  height: 300px;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.listing-card-image.transparent {
  z-index: 2;
}

.listing-card-image.overflow {
  height: 400px;
  overflow: hidden;
}

.listing-card {
  z-index: 2;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.listing-card.transparent {
  z-index: 2;
  width: 300px;
  height: 300px;
}

.listing-card.overflow {
  height: 400px;
  overflow: hidden;
}

.listing-card-image-overlay {
  width: 300px;
  height: 100%;
  background-image: linear-gradient(to right, var(--light-red), rgba(255, 234, 228, 0));
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.listing-card-image-overlay.turqoise {
  z-index: 1;
  width: 300px;
  background-image: linear-gradient(to right, var(--turqoise), rgba(213, 245, 243, 0));
}

.listing-card-image-overlay.cream {
  z-index: 1;
  background-image: linear-gradient(to right, var(--light-cream), rgba(240, 239, 227, 0));
}

.listing-card-image-overlay.blue {
  z-index: 1;
  background-image: linear-gradient(to right, var(--light-blue), rgba(224, 242, 255, 0));
}

.listing-card-image-overlay.red {
  z-index: 1;
}

.listing-card-image-overlay.soft-gray {
  background-image: linear-gradient(to right, var(--soft-gray), rgba(248, 249, 250, 0));
}

.card-image-overlay-copy {
  width: 500px;
  height: 100%;
  background-image: linear-gradient(to right, var(--light-red), rgba(255, 234, 228, 0));
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.card-image-overlay-copy.turqoise {
  z-index: 1;
  width: 250px;
  background-image: linear-gradient(to right, var(--turqoise), rgba(213, 245, 243, 0));
}

.card-image-overlay-copy.cream {
  z-index: 1;
  background-image: linear-gradient(to right, var(--light-cream), rgba(240, 239, 227, 0));
}

.card-image-overlay-copy.blue {
  z-index: 1;
  background-image: linear-gradient(to right, var(--light-blue), rgba(224, 242, 255, 0));
}

.card-image-overlay-copy.red {
  z-index: 1;
}

.card-image-overlay-copy.soft-gray {
  background-image: linear-gradient(to right, var(--soft-gray), rgba(248, 249, 250, 0));
}

.glossary-listing {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.pagination {
  margin-top: 20px;
}

.letter-card-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.brand {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  cursor: pointer;
  display: flex;
}

.brand.w--current {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.letter-heading {
  z-index: 2;
  color: var(--dark-text-color);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Inter, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  text-decoration: none;
  position: relative;
}

.content-card-link {
  flex-direction: column;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
  display: flex;
}

.content-card-link:hover {
  transform: scale(1.02);
}

.letter-card {
  height: 120px;
  white-space: normal;
  cursor: pointer;
  background-color: #e6e6e6;
  background-image: url('/images/Design-Element.svg');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 120%;
  border-radius: 10px;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 12px;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.letter-card:hover {
  background-size: 100%;
  transform: scale(1.02);
  box-shadow: 2px 2px 50px rgba(0, 0, 0, .2);
}

.letter-card.turqoise {
  background-color: var(--turqoise);
}

.letter-card.red {
  background-color: var(--light-red);
}

.letter-card.blue {
  background-color: var(--light-blue);
}

.letter-card.cream {
  background-color: var(--light-cream);
}

.footer-social-wrapper {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

.footer-link {
  color: #b6bcce;
  letter-spacing: -.01em;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition: all .2s;
}

.footer-link:hover {
  color: #fff;
  border: 1px solid #37456c;
}

.social-icon {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  background-color: var(--white);
  color: #061237;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 8px;
  transition: all .3s;
  display: flex;
}

.social-icon:hover {
  color: #1355ff;
  background-color: #fff;
}

.section-footer {
  background-color: var(--dark-text-color);
  align-items: center;
  padding: 100px 5%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.footer-logo {
  width: 124px;
  margin-bottom: 32px;
}

.footer-logo.cbyo-logo {
  width: 200px;
}

.footer-link-wrapper {
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  display: flex;
}

.container-large {
  z-index: 5;
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.paragraph-footer {
  max-width: 600px;
  color: #dadde7;
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

.icon-social {
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer-wrapper {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.footer-logo-wrapper {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  display: flex;
}

.listing-link {
  z-index: 4;
  cursor: pointer;
  border-radius: 30px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
  display: block;
  position: relative;
}

.listing-link:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}

.standard-listing-div {
  max-width: 1200px;
  min-height: 600px;
  background-color: #fff;
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 40px;
  position: relative;
  box-shadow: 0 0 100px rgba(0, 0, 0, .25);
}

.investment-office-logo {
  border-left: 1px solid var(--dark-text-color);
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
}

.wrap-v-x-small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.h5-heading {
  color: #0a0a0a;
  letter-spacing: -1.5px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  font-weight: 500;
  line-height: 1.4;
}

.content-grid-b {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.content-block {
  width: 100%;
  max-width: 488px;
  min-height: 488px;
  background-color: #fafafa;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.content-feature-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}

.content-wrapper {
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  flex-direction: column;
  display: flex;
}

.paragraph-small {
  color: var(--medium-dark-text);
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}

.subheading-small {
  color: #0a0a0a;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 32px;
}

.icon-regular-2 {
  width: 24px;
  height: 24px;
  flex: none;
  justify-content: center;
  align-items: center;
  transition: color .2s;
  display: flex;
}

.section-large {
  /* padding: 120px 5%; */
  padding: 10px 5%;
  position: relative;
}

.content-feature-wrap {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: flex-start;
  display: flex;
}

.text-color-purple-600 {
  color: #5c2d5c;
}

.caption-uppercase {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.paragraph-large-2 {
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
}

.paragraph-large-2.text-weight-medium {
  font-weight: 500;
}

.container-large-2 {
  z-index: 5;
  width: 100%;
  max-width: 1216px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.bold-text {
  color: var(--dark-text-color);
}

.finishing-image {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}


.invest-area {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #FDEFE0 0% 0% no-repeat padding-box;
  z-index: 9999;
  padding-bottom: 0vh;
  display: none;
  width: 100vw;
  overflow: auto;
}

.invest-area-wrapper {
  position: relative;
  z-index: 11;
  width: 100%;
  height: 100%;
}

.invest-area-box {
  width: 100%;
  display: none;
  border-bottom: 2px solid #FFFFFF;
}

.invest-area .form {
  padding-top: 2%;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.invest-area .form .search-button {
  margin-bottom: 30px;
  margin-right: 21px;
}

.invest-area .form input {
  font-size: 56px;
  background: none;
  outline: none;
  border: none;
  color: #334451;
  opacity: 0.49;
  width: 100%;
  width: calc(100% - 78px);
  width: -webkit-calc(100% - 78px);
  width: -moz-calc(100% - 78px);
}

.invest-area .form input::placeholder {
  font: normal normal 600 56px/67px;
  letter-spacing: 0px;
  color: #334451;
}

.invest-area .form button {
  font-size: 60px;
  color: #fff;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}



.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
}

.invest-area .close {
  position: absolute;
  /* bottom: 5%; */
  /* left: 0; */
  top: 35px;
  right: 40px;
  text-align: center;
  cursor: pointer;
}

.invest-button {
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
  border: 1px solid #E3E3E3;
  height: 58px;
  padding: 0 50px;
  min-width: 185px;
  line-height: 58px;
  text-align: center;
  color: #1D2A4B;
  font-weight: 900;
  font-size: 17px;
  display: inline-block;
  -webkit-transition: background .3s ease;
  -moz-transition: background .3s ease;
  -ms-transition: background .3s ease;
  -o-transition: background .3s ease;
  transition: background .3s ease;
}

.invest-close-button {
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  margin: 10px;
  background-color: #FF3823;
  border: 1px solid transparent;
  color: #fff;
  width: 66px;
  height: 66px;
  font-weight: 300;
  background: #ECBBAD 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.invest-close-button img {
  width: 28px;
  height: 28px;
  margin: auto;
}

.invest-button.full {
  background-color: #FF3823;
  border: 1px solid transparent;
}

.invest-area .close .invest-button {
  margin: 0 auto;
  color: #fff;
  width: 300px;
}

.search-list-container {
  width: 100%;
  background-image: url(/images/Design-Element.svg);
  background-position: 100% 100%, 0 0, 50% -250px;
  background-repeat: no-repeat, repeat, no-repeat;
  background-size: auto, auto, cover;
  background-attachment: scroll, scroll, scroll;
  min-height: 80%;
}

.search-list-container.searched {
  background-color: #fff;
}

.search-list-container .container {
  margin: 0 auto;
  height: 80%;
  overflow: auto;
  padding: 20px;
}

.search-list-container .container .row .gloosary-list-wrapper {
  padding: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #E2F2FF;
}

input:focus+.slider {
  box-shadow: 0 0 1px #E2F2FF;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.result-summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.results {
  font-size: 16px;
  color: #6B7094;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  margin-right: 10px;
  font-size: 16px;
  color: #6B7094;
  margin-bottom: 0;
  font-weight: unset;
}

.toggle-input {
  width: 80px;
  height: 40px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #a0c1f8;
  outline: none;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #6B709480;
}

.toggle-input:checked {
  background-color: #E2F2FF;
}

.fdi-button-shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.fdi-button-shadow:hover {
  transform: scale(1.02);
  box-shadow: 2px 2px 50px rgba(0, 0, 0, .2);
}

.toggle-input:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  top: 3px;
  left: 3px;
  transition: transform 0.3s;
  border: 1px solid #6B709480;
}

.toggle-input:checked:before {
  transform: translateX(39px);
}

.show-more-container {
  display: flex;
  margin-top: 40px;
}

.show-more-container .results {
  margin: auto 0;
}

.show-more-button {
  background: #F1EFE4 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #2424243E;
  border-radius: 12px;
  letter-spacing: 0px;
  color: #6B7094;
  width: 230px;
  height: 66px;
  font-size: 22px;
  margin: auto;
}

@media screen and (max-width: 991px) {
  .heading-set-2 {
    z-index: 3;
    position: relative;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .container.flex {
    align-items: stretch;
  }

  .hero-section {
    background-size: 40%, auto, cover;
  }

  .nav-button {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-container {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }

  .div-col-md-2,
  .div-col-md-2-2,
  .div-col-md-2-3,
  .div-col-md-2-4,
  .div-col-md-2-5,
  .div-col-md-2-6,
  .div-col-md-2-7 {
    display: none;
  }

  .link-15 {
    align-items: center;
  }

  .newsletter-block {
    align-items: center;
    position: static;
  }

  .button-primary {
    display: flex;
  }

  .glossary-content-grid {
    flex-direction: column-reverse;
    grid-template-columns: 1fr;
    display: flex;
  }

  .glossary-list {
    width: 100%;
  }

  .glossary-letter-wrap {
    flex-direction: column;
    align-items: flex-end;
  }

  .container-2 {
    padding-left: 3em;
    padding-right: 3em;
  }

  .term-detail-overlap {
    padding-top: 40px;
    padding-bottom: 40px;
    transform: translate(0, -100px);
  }

  .content-card {
    grid-template-columns: 2fr 1fr;
  }

  .card-image {
    width: 200px;
    height: 200px;
  }

  .card-image.transparent {
    width: 200px;
    height: 200px;
    display: block;
  }

  .collection-list {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .letter-card-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .section-footer {
    padding-bottom: 140px;
  }

  .footer-link-wrapper {
    flex-wrap: wrap;
  }

  .content-grid-b {
    grid-template-columns: 1fr;
  }

  .content-block {
    max-width: none;
  }

  .content-wrapper {
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .heading-1 {
    font-size: 50px;
    line-height: 60px;
  }

  .dynamic-cards-section {
    padding-top: 0;
  }

  .glossary-search {
    max-width: none;
  }

  .section-regular {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .glossary-content-grid {
    flex-direction: column-reverse;
    display: flex;
  }

  .h2-heading {
    font-size: 44px;
  }

  .marquee-wrapper {
    font-size: .7em;
  }

  .hero-overlay {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgba(255, 255, 255, .2)), linear-gradient(to right, var(--soft-gray), rgba(230, 230, 230, 0));
  }

  .h3-heading {
    font-size: 36px;
  }

  .listing-card-heading {
    font-size: 32px;
  }

  .letter-card-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .footer-wrapper {
    margin-bottom: 60px;
  }

  .h5-heading {
    font-size: 32px;
  }

  .content-grid-b {
    grid-template-columns: 1fr;
  }

  .section-large {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 479px) {
  .heading-1 {
    font-size: 32px;
    line-height: 40px;
  }

  .description {
    font-size: 16px;
    line-height: 28px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }

  .container.no-overflow {
    overflow: visible;
  }

  .hero-section {
    height: 520px;
    padding-top: 100px;
  }



  .navbar {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .vectors-wrapper {
    width: 100px;
    height: auto;
  }

  .nav-button {
    padding: 10px;
    font-size: 14px;
  }

  .navbar-container,
  .section {
    padding-left: 0;
    padding-right: 0;
  }

  .div-row-2 {
    flex-direction: row;
  }

  .link-15 {
    padding-right: 0;
  }

  .overlapping-div {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .glossary-letter-group {
    justify-content: center;
  }

  .newsletter-block {
    padding: 24px;
  }

  .glossary-search {
    flex-direction: column;
  }

  .paragraph-large {
    font-size: 16px;
  }

  .glossary-input,
  .button-primary {
    width: 100%;
  }

  .sliding-cards-section {
    height: 300px;
  }

  .marquee-wrapper {
    padding-left: 40px;
  }

  .dynamic-hero-section {
    height: auto;
    min-height: 380px;
    background-position: 50% 100%, 0%, 0 0;
    background-size: cover, auto, auto;
    align-items: flex-end;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
  }

  .hero-overlay.red {
    background-image: linear-gradient(to top, var(--light-red), rgba(255, 234, 228, 0) 50%);
  }

  .hero-overlay.blue {
    background-image: linear-gradient(to top, var(--light-blue), rgba(224, 242, 255, 0) 50%);
  }

  .hero-overlay.turqoise {
    background-image: linear-gradient(to top, var(--turqoise), rgba(213, 245, 243, 0) 50%);
  }

  .hero-overlay.cream {
    background-image: linear-gradient(to top, var(--light-cream), rgba(240, 239, 227, 0) 50%, rgba(240, 239, 227, 0));
    flex: 0 auto;
    display: flex;
  }

  .hero-overlay.hide {
    width: 400px;
    height: 400px;
  }

  .term-detail-overlap {
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    transform: translate(0, -40px);
  }

  .content-card {
    grid-template-columns: 2fr;
    justify-items: center;
    padding: 20px;
  }

  .card-image {
    position: relative;
  }

  .card-image-overlay {
    display: none;
  }

  .letter-card-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .letter-heading {
    font-size: 2rem;
    line-height: 2rem;
  }

  .letter-card {
    justify-content: flex-end;
    align-items: center;
  }

  .footer-link {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .section-footer {
    padding-bottom: 100px;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .footer-logo-wrapper {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
  }

  .standard-listing-div {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-3 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .investment-office-logo {
    padding-left: 10px;
    display: none;
  }

  .content-block {
    min-height: auto;
    padding: 24px;
  }

  .content-feature-grid {
    grid-template-columns: 1fr;
  }

  .invest-area .form .search-button {
    width: 27px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .invest-area .form {
    padding-top: 4%;
    width: 82%;
  }

  .invest-area .form input,
  .invest-area .form input::placeholder {
    font-size: 24px;
  }

  .invest-area .close {
    top: 0px;
    right: 5px;
  }

  .invest-close-button {
    width: 35px;
    height: 35px;
  }

  .result-summary-container {
    padding: 0;
    margin-bottom: 10px;
  }

  .invest-close-button img {
    width: 16px;
    height: 16px;
  }

  .toggle-input {
    width: 70px;
    height: 40px;
  }

  .toggle-input:before {
    width: 30px;
    height: 30px;
    top: 3px;
    left:3px;
  }

  .toggle-input:checked:before {
    transform: translateX(28px);
  }

  .show-more-container {
    flex-direction: column;
  }

  .show-more-container .results {
    margin: 10px auto;
  }

  .nav-button.fdi-term-list {
    font-size: 13px;
    height: 50px;
    padding: 12px;
    width: 120px;
    text-align: center;
  }

  .navlinks .search {
    width: 50px;
    height: 50px;
    margin-left: 15px;
  }

  .navlinks .search img {
    width: 18px;
    display: block;
  }

}

#w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04233-834f41c4,
#w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04240-834f41c4,
#w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04247-834f41c4,
#w-node-bcc1bd44-2395-603a-ef7b-8b6f77c0424e-834f41c4,
#w-node-bcc1bd44-2395-603a-ef7b-8b6f77c04255-834f41c4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bcc1bd44-2395-603a-ef7b-8b6f77c0425c-834f41c4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a400aed3-de7e-0a32-1e10-48d4e872ee6b-834f41c4,
#w-node-a400aed3-de7e-0a32-1e10-48d4e872ee6c-834f41c4,
#w-node-a400aed3-de7e-0a32-1e10-48d4e872ee6d-834f41c4,
#w-node-a400aed3-de7e-0a32-1e10-48d4e872ee6e-834f41c4,
#w-node-a400aed3-de7e-0a32-1e10-48d4e872ee6f-834f41c4,
#w-node-a400aed3-de7e-0a32-1e10-48d4e872ee70-834f41c4,
#w-node-_03af0dd4-ebd4-4c0b-8bbe-275ced51d755-834f41c4,
#w-node-_7357898e-23fe-7a9f-dd7e-c02d6822c79f-57a7ec2b,
#w-node-_7ba80b51-0a00-da04-94e6-a563535ffaa1-57a7ec2b,
#w-node-a67db8ae-4a62-7e67-198e-219720d3f7c2-57a7ec2b,
#w-node-_17e1de61-7e7c-e547-8826-331f7c1c623e-57a7ec2b,
#w-node-_645dadcc-f838-7fd0-bebb-69e46a4e1af0-57a7ec2b,
#w-node-_8d11c7c0-46d4-0527-3ee4-777c8027fab9-57a7ec2b,
#w-node-_01463752-1deb-94bd-70f6-136ae4f197b1-57a7ec2b,
#w-node-e7f18829-587e-22bf-9196-969cc7c19d95-57a7ec2b,
#w-node-_9c714c94-9ac7-0001-8d65-cece948f3ba2-57a7ec2b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9c714c94-9ac7-0001-8d65-cece948f3bdb-57a7ec2b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_4e8ab306-cc0f-dc92-319a-c28fab0a28b4-d4f4daa3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4e8ab306-cc0f-dc92-319a-c28fab0a28ba-d4f4daa3 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_4e8ab306-cc0f-dc92-319a-c28fab0a28cb-d4f4daa3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-a689de95-3b5d-a8be-35eb-6bb712039d30-d4f4daa3,
#w-node-_6c8438d0-a7d7-8c3f-0c55-dbd6c89c553b-09f1eb78 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6950ab59-a50a-96e0-668e-0ebb5626b86e-09f1eb78 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_6c8438d0-a7d7-8c3f-0c55-dbd6c89c5574-09f1eb78 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}



.marquee-track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-horizontal 480s linear infinite;
}

@keyframes marquee-horizontal {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 992px) {
  .marquee-track:hover {
    animation-play-state: paused;
  }
}

/* Disable marquee on mobile devices */
@media only screen and (max-width: 991px) {
  .marquee-track {
    animation: none;
  }
}

.jetboost-list-item-hide {
  display: none !important;
}

.jetboost-hidden {
  visibility: hidden !important;
}

@keyframes jetboost-fadeout-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes jetboost-fadein-animation {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}